export const agencyData = [
    { id: "0", title: "DIGITAL MARKETING AGENCY", value: "DIGITAL_MARKETING_AGENCY", label: "DIGITAL_MARKETING_AGENCY" },
    { id: "1", title: "ADVERTISING AGENCY", value: "ADVERTISING_AGENCY", label: "ADVERTISING_AGENCY" },
    { id: "2", title: "PUBLIC RELATIONS AGENCY", value: "PUBLIC_RELATIONS_AGENCY", label: "PUBLIC_RELATIONS_AGENCY" },
    { id: "3", title: "CREATIVE AGENCY", value: "CREATIVE_AGENCY", label: "CREATIVE_AGENCY" },
    { id: "4", title: "ONLINE MARKETING AGENCY", value: "ONLINE_MARKETING_AGENCY", label: "ONLINE_MARKETING_AGENCY" },
    { id: "5", title: "SEO AGENCY", value: "SEO_AGENCY", label: "SEO_AGENCY" },
    { id: "6", title: "WEB DEVELOPMENT AGENCY", value: "WEB_DEVELOPMENT_AGENCY", label: "WEB_DEVELOPMENT_AGENCY" },
    { id: "7", title: "SOCIAL MEDIA AGENCY", value: "SOCIAL_MEDIA_AGENCY", label: "SOCIAL_MEDIA_AGENCY" },
    { id: "8", title: "CONTENT MARKETING AGENCY", value: "CONTENT_MARKETING_AGENCY", label: "CONTENT_MARKETING_AGENCY" },
    { id: "9", title: "BRANDING AGENCY", value: "BRANDING_AGENCY", label: "BRANDING_AGENCY" },
    { id: "11", title: "GRAPHIC DESIGN AGENCY", value: "GRAPHIC_DESIGN_AGENCY", label: "GRAPHIC_DESIGN_AGENCY" },
    { id: "12", title: "MARKETING CONSULTANCY", value: "MARKETING_CONSULTANCY", label: "MARKETING_CONSULTANCY" },
    { id: "15", title: "EMAIL MARKETING AGENCY", value: "EMAIL_MARKETING_AGENCY", label: "EMAIL_MARKETING_AGENCY" },
    { id: "16", title: "DATA ANALYTICS AGENCY", value: "DATA_ANALYTICS_AGENCY", label: "DATA_ANALYTICS_AGENCY" },
    { id: "17", title: "PPC MANAGEMENT AGENCY", value: "PPC_MANAGEMENT_AGENCY", label: "PPC_MANAGEMENT_AGENCY" },
    { id: "18", title: "E-COMMERCE AGENCY", value: "E_COMMERCE_AGENCY", label: "E_COMMERCE_AGENCY" },
    { id: "19", title: "UX/UI DESIGN AGENCY", value: "UX_UI_DESIGN_AGENCY", label: "UX_UI_DESIGN_AGENCY" },
    { id: "20", title: "MOBILE APP MARKETING AGENCY", value: "MOBILE_APP_MARKETING_AGENCY", label: "MOBILE_APP_MARKETING_AGENCY" }
];
