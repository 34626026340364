export const marketPlaceCategoryData = [
  { id: "0", title: "Cloud Computing Platforms", value: "CLOUD_PLATFORMS", label: "CLOUD_PLATFORMS" },
  { id: "1", title: "Database Management Systems", value: "DATABASE_MANAGEMENT", label: "DATABASE_MANAGEMENT" },
  { id: "2", title: "Version Control Systems", value: "VERSION_CONTROL", label: "VERSION_CONTROL" },
  { id: "3", title: "Containerization Tools", value: "CONTAINERIZATION", label: "CONTAINERIZATION" },
  { id: "4", title: "CI/CD Tools", value: "CI_CD_TOOLS", label: "CI_CD_TOOLS" },
  { id: "5", title: "Monitoring and Logging Tools", value: "MONITORING_LOGGING", label: "MONITORING_LOGGING" },
  { id: "6", title: "Project Management Tools", value: "PROJECT_MANAGEMENT", label: "PROJECT_MANAGEMENT" },
  { id: "7", title: "Collaboration Tools", value: "COLLABORATION", label: "COLLABORATION" },
  { id: "8", title: "API Management Tools", value: "API_MANAGEMENT", label: "API_MANAGEMENT" },
  { id: "9", title: "Data Visualization Tools", value: "DATA_VISUALIZATION", label: "DATA_VISUALIZATION" },
  { id: "10", title: "Machine Learning Platforms", value: "ML_PLATFORMS", label: "ML_PLATFORMS" },
  { id: "11", title: "Cybersecurity Tools", value: "CYBERSECURITY", label: "CYBERSECURITY" },
  { id: "12", title: "Networking Tools", value: "NETWORKING", label: "NETWORKING" },
  { id: "13", title: "Virtualization Software", value: "VIRTUALIZATION", label: "VIRTUALIZATION" },
  { id: "14", title: "Content Management Systems", value: "CMS", label: "CMS" },
  { id: "15", title: "E-commerce Platforms", value: "ECOMMERCE", label: "ECOMMERCE" },
  { id: "16", title: "Web Development Frameworks", value: "WEB_FRAMEWORKS", label: "WEB_FRAMEWORKS" },
  { id: "17", title: "Mobile Development Frameworks", value: "MOBILE_FRAMEWORKS", label: "MOBILE_FRAMEWORKS" },
  { id: "18", title: "DevOps Tools", value: "DEVOPS_TOOLS", label: "DEVOPS_TOOLS" },
  { id: "19", title: "Automation Tools", value: "AUTOMATION", label: "AUTOMATION" },
  { id: "20", title: "Testing Tools", value: "TESTING", label: "TESTING" },
  { id: "21", title: "Data Warehousing Solutions", value: "DATA_WAREHOUSING", label: "DATA_WAREHOUSING" },
  { id: "22", title: "Big Data Platforms", value: "BIG_DATA", label: "BIG_DATA" },
  { id: "23", title: "Business Intelligence Tools", value: "BI_TOOLS", label: "BI_TOOLS" },
  { id: "24", title: "ERP Systems", value: "ERP", label: "ERP" },
  { id: "25", title: "CRM Systems", value: "CRM", label: "CRM" },
  { id: "26", title: "IT Service Management Tools", value: "ITSM", label: "ITSM" },
  { id: "27", title: "Low-Code/No-Code Platforms", value: "LOW_CODE", label: "LOW_CODE" },
  { id: "28", title: "Blockchain Platforms", value: "BLOCKCHAIN", label: "BLOCKCHAIN" },
  { id: "29", title: "Game Development Engines", value: "GAME_ENGINES", label: "GAME_ENGINES" },
  { id: "30", title: "AI Development Platforms", value: "AI_PLATFORMS", label: "AI_PLATFORMS" },
  { id: "31", title: "Data Integration Tools", value: "DATA_INTEGRATION", label: "DATA_INTEGRATION" },
  { id: "32", title: "API Gateway Solutions", value: "API_GATEWAY", label: "API_GATEWAY" },
  { id: "33", title: "Load Testing Tools", value: "LOAD_TESTING", label: "LOAD_TESTING" },
  { id: "34", title: "Code Editors and IDEs", value: "CODE_EDITORS", label: "CODE_EDITORS" },
  { id: "35", title: "Static Site Generators", value: "STATIC_SITE_GENERATORS", label: "STATIC_SITE_GENERATORS" },
  { id: "36", title: "Serverless Platforms", value: "SERVERLESS", label: "SERVERLESS" },
  { id: "37", title: "Data Governance Tools", value: "DATA_GOVERNANCE", label: "DATA_GOVERNANCE" },
  { id: "38", title: "IT Asset Management Tools", value: "IT_ASSET_MANAGEMENT", label: "IT_ASSET_MANAGEMENT" },
  { id: "39", title: "Disaster Recovery Solutions", value: "DISASTER_RECOVERY", label: "DISASTER_RECOVERY" },
  { id: "40", title: "Backup Solutions", value: "BACKUP", label: "BACKUP" },
  { id: "41", title: "Endpoint Security Tools", value: "ENDPOINT_SECURITY", label: "ENDPOINT_SECURITY" },
  { id: "42", title: "Identity and Access Management Tools", value: "IAM", label: "IAM" },
  { id: "43", title: "Compliance Management Tools", value: "COMPLIANCE", label: "COMPLIANCE" },
  { id: "44", title: "Data Loss Prevention Tools", value: "DLP", label: "DLP" },
  { id: "45", title: "Network Security Tools", value: "NETWORK_SECURITY", label: "NETWORK_SECURITY" },
  { id: "46", title: "Penetration Testing Tools", value: "PEN_TESTING", label: "PEN_TESTING" },
  { id: "47", title: "Threat Intelligence Platforms", value: "THREAT_INTELLIGENCE", label: "THREAT_INTELLIGENCE" },
  { id: "48", title: "Incident Response Tools", value: "INCIDENT_RESPONSE", label: "INCIDENT_RESPONSE" },
  { id: "49", title: "Vulnerability Management Tools", value: "VULNERABILITY_MANAGEMENT", label: "VULNERABILITY_MANAGEMENT" },
  { id: "50", title: "Patch Management Tools", value: "PATCH_MANAGEMENT", label: "PATCH_MANAGEMENT" },
  { id: "51", title: "Unified Communications Tools", value: "UNIFIED_COMMUNICATIONS", label: "UNIFIED_COMMUNICATIONS" },
  { id: "52", title: "Video Conferencing Tools", value: "VIDEO_CONFERENCING", label: "VIDEO_CONFERENCING" },
  { id: "53", title: "IT Documentation Tools", value: "IT_DOCUMENTATION", label: "IT_DOCUMENTATION" },
  { id: "54", title: "Knowledge Management Systems", value: "KNOWLEDGE_MANAGEMENT", label: "KNOWLEDGE_MANAGEMENT" },
  { id: "55", title: "IT Training Platforms", value: "IT_TRAINING", label: "IT_TRAINING" },
];